import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "gatsby";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomButton from "components/CustomButtons/CustomButton.jsx";
import ButtonESG from "components/CustomButtons/ButtonESG.jsx";
import ButtonPartners from "components/CustomButtons/ButtonPartners.jsx";
import ButtonContact from "components/CustomButtons/ButtonContact.jsx";
import DownloadButton from "components/CustomButtons/DownloadButton.jsx";
import SpecButton from "components/CustomButtons/SpecButton.jsx";

import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionBasics from "./Sections/SectionBasics.jsx";
import SectionNavbars from "./Sections/SectionNavbars.jsx";
import SectionTabs from "./Sections/SectionTabs.jsx";
import SectionPills from "./Sections/SectionPills.jsx";
import SectionNotifications from "./Sections/SectionNotifications.jsx";
import SectionTypography from "./Sections/SectionTypography.jsx";
import SectionJavascript from "./Sections/SectionJavascript.jsx";
import SectionCarousel from "./Sections/SectionCarousel.jsx";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.jsx";
import SectionLogin from "./Sections/SectionLogin.jsx";
import SectionExamples from "./Sections/SectionExamples.jsx";
import SectionDownload from "./Sections/SectionDownload.jsx";
import ProductSection from "./Sections/ProductSection.jsx";

import portrait from "../../assets/img/portrait2.png";
import specshome from "../../assets/img/specshome.png";
import footimg from "../../assets/img/footimg.png";
import image from "../../assets/img/footimg.png";
import dubaiimage from "../../assets/img/Dubai.jpg";
import contactheader from "../../assets/img/ContactHeader.jpg";
import partnersheader from "../../assets/img/OurPartnersHeader.jpg";
import esgheader from "../../assets/img/ESGHeader.jpg";
import colliery from "../../assets/img/colliery.png";

import wwdStyle from "assets/jss/material-kit-react/views/wwdPage.jsx";

class WWDPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/wwd.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}>
                <div className={classes.intro}>
                  What we do
                  </div>
                  
                  <div className={classes.subtitle}>
                  Meeting our clients’ long-term objectives

                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
          <div className={classes.preheading}>
          Development and Development Management
                  </div>
                 
                <div className={classes.parawrap}>
                <p className={classes.paratext}>
                Our team has extensive experience in creating value for landowners and investors through the end-to-end management of commercial real estate developments from feasibility to delivery and exit. Our ability to unlock value for our clients stems from focused procurement strategies and our single minded approach to finding efficiencies in building design and the delivery supply chain.
                </p>
                </div>
                <div className={classes.preheading}>
          Build-to-suit
                  </div>
                  <div className={classes.parawrap}>
                  <p className={classes.paratext}>
                  We have extensive global experience in the build-to-suit process and our team has delivered bespoke builds for multi-national blue chip occupiers including IKEA, DSV and Honeywell. Our acute understanding of client requirements enable us to support landowners, investors and occupiers in creating successful long-term solutions and investment opportunities.
                </p>
            
              </div>
                
            </GridItem>
            </GridContainer>

            </div>  
          </div>

          <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrapfoot}>
          {/* <ProductSection /> */}

          <div className={classes.bgimg}       style={{
            backgroundImage: "url(" + dubaiimage + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}>
                    <div className={classes.container}>
            <GridContainer>
              <GridItem>
              <div className={classes.brand}>
                  
                  <div className={classes.subtitle}>
                  “We have extensive global experience in the build-to-suit process and our team has delivered bespoke builds for multi-national blue chip occupiers including IKEA, DSV and Honeywell.”

                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          </div>

            </div>  
          </div>

          <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
          <div className={classes.preheading}>
          Investment
                  </div>
                 
                <div className={classes.parawrap}>
                <p className={classes.paratext}>
                We believe in what we do, so we also invest our own equity as well as that of our clients. On behalf of clients, our team acquires, manages and transacts commercial property, creating the correct ownership and finance structures to best meet our customers’ objectives.
                </p>
                </div>
                <div className={classes.preheading}>
                Asset Management
                  </div>
                  <div className={classes.parawrap}>
                  <p className={classes.paratext}>
                  Through our systematic processes we help our clients deploy, operate, maintain, upgrade and realise real estate assets cost-effectively. We offer a range of asset management services, all of which focus on improving value and life cycle returns from our clients‘ real estate investment.
                    <br/>
                    <br/>
                    We have particularly strong experience in the areas of staff accommodation and logistics.
                    </p>
                    </div>
                    <div className={classes.preheading}>
                    Co-investment Structuring
                  </div>
                  <div className={classes.parawrap}>
                <p className={classes.paratext}>
                With over thirty years combined experience our multi-disciplined team has enviable experience in creating simple co-invest structures and JV’s to allow managed exposure to development and investment opportunities.

                </p>
                </div>
            </GridItem>
            </GridContainer>

            </div>  
          </div>

          <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrapfoot}>
          {/* <ProductSection /> */}

          <div className={classes.bgimg}       style={{
            backgroundImage: "url(" + contactheader + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}>
                    <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brandfooter}>
                <div className={classes.introcontact}>
                  Contact us
                  </div>
                <ButtonContact/>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          </div>

            </div>  
          </div>

        <div className={classNames(classes.mainlinks, classes.mainRaisedlinks)}>

            <div className={classes.mainwraplinks}>
            <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={esgheader} className={classes.homeimage} />
          </div>
          <div className={classes.parawraplinks}>
          <ButtonESG/>
                <p className={classes.paratextlinks}>
                Find out more about our team of experienced real estate professionals.
                </p>
                
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={partnersheader} className={classes.homeimage} />
          </div>
          <div className={classes.parawraplinks}>
          <ButtonPartners/>
                <p className={classes.paratextlinks}>
                Asset management, development, build, investment and equity services.
                </p>
            
              </div>
            </GridItem>
            
            </GridContainer>

            </div>   
        </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(wwdStyle)(WWDPage);